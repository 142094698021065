
















import BackButtonTitle from '@/components/shared/BackButtonTitle.vue';
import LocationForm from '@/components/locations/LocationForm.vue';
import BaseLocationMixin from '@/components/locations/BaseLocationMixin';
import Vue, { VueConstructor } from 'vue';
import ResourceMixin from '@/mixins/ResourceMixin';
import { LocationPanel, useUiStore } from '@/store/ui-store';
import { mapStores } from 'pinia';
import { useLocationsStore } from '@/store/locations-store';
import { Collection } from '@/store/common/collections';

export default (Vue as VueConstructor<
  Vue &
    InstanceType<typeof ResourceMixin> &
    InstanceType<typeof BaseLocationMixin>
>).extend({
  components: {
    LocationForm,
    BackButtonTitle
  },
  mixins: [ResourceMixin, BaseLocationMixin],
  props: {
    withAssociations: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapStores(useUiStore, useLocationsStore)
  },
  methods: {
    closeCurrentPanel() {
      this.uiStore.closePanel(LocationPanel.ADD_LOCATION);
    },
    async addLocation({ location }) {
      await this.locationsStore.create(location, [Collection.REGIONS]);

      if (
        !this.sendingData &&
        Object.keys(this.validationErrors).length === 0
      ) {
        this.closeCurrentPanel();
      }

      if (!this.withAssociations) {
        this.uiStore.addNotification(Collection.LOCATIONS);
      }
    }
  }
});
