











































import TextInput from '@/components/shared/forms/TextInput.vue';
import MultiSelectInput from '@/components/shared/forms/MultiSelectInput.vue';
import SubmitButton from '@/components/shared/SubmitButton.vue';
import { mapStores } from 'pinia';
import { useRegionsStore } from '@/store/regions-store';
import { useLocationsStore } from '@/store/locations-store';
import {
  RegionPanel,
  REGIONS_PANELS_LAYOUT,
  useUiStore
} from '@/store/ui-store';
import Vue from 'vue';
import { Location } from '@/model/location';
import ButtonGroup from '@/components/shared/ButtonGroup.vue';
import PlainButton from '@/components/shared/PlainButton.vue';

export default Vue.extend({
  components: {
    TextInput,
    MultiSelectInput,
    SubmitButton,
    ButtonGroup,
    PlainButton
  },
  props: {
    regionToEdit: {
      type: Object,
      default: () => {}
    },
    withAssociations: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      region: {},
      associatedLocations: [] as Location[]
    };
  },
  computed: {
    ...mapStores(useLocationsStore, useRegionsStore, useUiStore),
    validationErrors(): {} {
      return this.regionsStore.validationErrors;
    },
    sendingData(): boolean {
      return this.regionsStore.saving;
    },
    locations(): Location[] {
      return this.locationsStore.entities;
    },
    availableLocations(): Location[] {
      return this.locations.filter(location => {
        return location.region_id === null;
      });
    }
  },
  watch: {
    regionToEdit() {
      this.fillExistingData();
    }
  },
  created() {
    this.fillExistingData();
  },
  methods: {
    fillExistingData() {
      this.region = {
        id: this.regionToEdit ? this.regionToEdit.id : '',
        name: this.regionToEdit ? this.regionToEdit.name : ''
      };
      this.associatedLocations = this.regionToEdit
        ? this.regionToEdit.locations
        : [];
    },
    addLocation() {
      this.uiStore.openPanel(REGIONS_PANELS_LAYOUT, RegionPanel.ADD_LOCATION);
    },
    onSubmit() {
      this.$emit('onFormValidated', {
        region: this.region,
        locations: this.associatedLocations
      });
    }
  }
});
