var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isFetchingLocations && _vm.isFetchingRegions)?_c('loading-indicator'):_vm._e(),(!_vm.isFetchingLocations && !_vm.isFetchingRegions)?_c('div',[_c('search',{attrs:{"done-typing-interval":0,"default-value":_vm.searchTerms,"placeholder":"Nom du lieu","icon":"fas fa-filter"},on:{"onSearch":function($event){_vm.searchTerms = $event}}}),(_vm.locations.length === 0)?_c('empty-dataset',{attrs:{"text":_vm.$t('placeholder.location'),"icon":"map"}}):_vm._e(),_vm._l((_vm.getRegionsToLoop),function(region,index){return _c('div',{key:index},[(
          _vm.getLocationsFiltered(_vm.getLocationsForRegionId(region.id)).length > 0
        )?_c('div',[_c('div',{staticClass:"tw-text-sm tw-pt-1 tw-pb-3 tw-uppercase tw-font-normal tw-font"},[_vm._v(" "+_vm._s(region.name)+" ")]),_vm._l((_vm.getLocationsFiltered(
            _vm.getLocationsForRegionId(region.id)
          )),function(location){return _c('div',{key:location.id,class:{
            active: _vm.currentLocation && _vm.currentLocation.id === location.id
          },on:{"click":function($event){return _vm.editLocation(location)}}},[_c('config-list-element',{attrs:{"current-entity":_vm.currentLocation,"entity":location,"collection":_vm.COLLECTION.LOCATIONS}},[_vm._v(" "+_vm._s(location.name)+" "),_c('span',{staticClass:"tw-ml-2 tw-inline-flex tw-items-center tw-px-2.5 tw-py-0.5 tw-rounded-full tw-text-xs tw-font-medium",class:[
                _vm.isClosed(location)
                  ? 'tw-bg-danger-100 tw-text-danger-800'
                  : _vm.isClosingPlanned(location)
                  ? 'tw-bg-warning-100 tw-text-warning-800'
                  : 'tw-bg-success-100 tw-text-success-800'
              ]},[_vm._v(" "+_vm._s(_vm.isClosed(location) ? _vm.$t('locations.closed') : _vm.$t('locations.open'))+" ")])])],1)})],2):_vm._e()])})],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }