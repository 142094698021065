import { mapStores } from 'pinia';
import { useRegionsStore } from '@/store/regions-store';
import { Region } from '@/model/region';
import { Collection } from '@/store/common/collections';
import Vue, { VueConstructor } from 'vue';
import ResourceMixin from '@/mixins/ResourceMixin';

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof ResourceMixin>
>).extend({
  mixins: [ResourceMixin],
  computed: {
    ...mapStores(useRegionsStore),
    currentRegion(): Region {
      return this.regionsStore.currentEntity;
    },
    validationErrors(): {} {
      return this.regionsStore.validationErrors;
    },
    sendingData(): boolean {
      return this.regionsStore.saving;
    }
  },
  methods: {
    regionForApi({ region, locations }) {
      const locationsForApi = this.getCollectionForApi(locations);

      region = {
        ...region,
        [Collection.LOCATIONS]: locationsForApi
      };

      return region;
    }
  }
});
