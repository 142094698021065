import { mapStores } from 'pinia';
import { useLocationsStore } from '@/store/locations-store';
import Vue, { VueConstructor } from 'vue';
import ResourceMixin from '@/mixins/ResourceMixin';
import { Location } from '@/model/location';
import { Collection } from '@/store/common/collections';

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof ResourceMixin>
>).extend({
  mixins: [ResourceMixin],
  computed: {
    ...mapStores(useLocationsStore),
    currentLocation(): Location {
      return this.locationsStore.currentEntity;
    },
    validationErrors(): {} {
      return this.locationsStore.validationErrors;
    },
    sendingData(): boolean {
      return this.locationsStore.saving;
    }
  }
});
