var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{staticClass:"mb-4",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit()}}},[_c('color-input',{attrs:{"label":_vm.$t('location.color'),"name":"color","color":_vm.location.color,"errors":_vm.validationErrors},on:{"onValueChange":function (ref) {
        var color = ref.color;

        _vm.location.color = color;
      }}}),_c('text-input',{attrs:{"value":_vm.location.name,"errors":_vm.validationErrors,"name":"name","label":_vm.$t('label.name'),"help-text":_vm.$t('location.nameHint')},on:{"onValueChange":function (value) {
        _vm.location.name = value;
      }}}),_c('text-input',{attrs:{"value":_vm.location.short_name,"errors":_vm.validationErrors,"name":"short_name","label":_vm.$t('label.shortName'),"help-text":_vm.$t('location.shortNameHint')},on:{"onValueChange":function (value) {
        _vm.location.short_name = value.toUpperCase();
      }}}),_c('text-input',{attrs:{"value":_vm.location.street,"errors":_vm.validationErrors,"name":"address","label":_vm.$t('label.address')},on:{"onValueChange":function (value) {
        _vm.location.street = value;
      }}}),_c('text-input',{attrs:{"value":_vm.location.complement,"errors":_vm.validationErrors,"name":"complement","optional":true,"label":_vm.$t('label.addressComplement')},on:{"onValueChange":function (value) {
        _vm.location.complement = value;
      }}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-4"},[_c('text-input',{attrs:{"value":_vm.location.zip,"errors":_vm.validationErrors,"name":"zip","label":_vm.$t('label.zip')},on:{"onValueChange":function (value) {
            _vm.location.zip = value;
          }}})],1),_c('div',{staticClass:"col-8"},[_c('text-input',{attrs:{"value":_vm.location.city,"errors":_vm.validationErrors,"name":"city","label":_vm.$t('label.city')},on:{"onValueChange":function (value) {
            _vm.location.city = value;
          }}})],1)]),(_vm.withAssociations && _vm.isFromLPittet)?_c('div',{staticClass:"border-top mt-4 pt-3"},[_c('div',{staticClass:"mb-3"},[_c('select-input',{attrs:{"value":_vm.location.region_id,"errors":_vm.validationErrors,"placeholder":_vm.$t('locations.noRegion'),"name":"region_id","label":_vm.$t('label.region'),"allow-empty":true,"options":_vm.regionsOptions,"custom-label":function (ref) {
                var name = ref.name;

                return name;
}},on:{"onValueChange":function (value) {
            _vm.location.region_id = parseInt(value, 10);
          }}}),_c('plain-button',{attrs:{"icon":"plus","size":"xs","button-link":"","right":"","text-color":"primary-500"},nativeOn:{"click":function($event){return _vm.addRegion()}}},[_vm._v(" "+_vm._s(_vm.$t('button.createNewRegion'))+" ")])],1)]):_vm._e(),_c('button-group',[_c('submit-button',{attrs:{"loading":_vm.sendingData,"label":_vm.location.id ? _vm.$t('button.save') : _vm.$t('button.add')}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }