
















import RegionForm from '@/components/regions/RegionForm.vue';
import BackButtonTitle from '@/components/shared/BackButtonTitle.vue';
import BaseRegionMixin from '@/components/regions/BaseRegionMixin';
import Vue, { VueConstructor } from 'vue';
import ResourceMixin from '@/mixins/ResourceMixin';
import { Collection } from '@/store/common/collections';
import { RegionPanel, useUiStore } from '@/store/ui-store';
import { mapStores } from 'pinia';
import { useRegionsStore } from '@/store/regions-store';

export default (Vue as VueConstructor<
  Vue &
    InstanceType<typeof ResourceMixin> &
    InstanceType<typeof BaseRegionMixin>
>).extend({
  components: {
    RegionForm,
    BackButtonTitle
  },
  mixins: [ResourceMixin, BaseRegionMixin],
  props: {
    withAssociations: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapStores(useUiStore, useRegionsStore)
  },
  methods: {
    closeCurrentPanel() {
      this.uiStore.closePanel(RegionPanel.ADD_REGION);
    },
    async addRegion({ region, locations }) {
      region = this.regionForApi({ region, locations });
      await this.regionsStore.create(region);

      if (
        !this.sendingData &&
        Object.keys(this.validationErrors).length === 0
      ) {
        this.closeCurrentPanel();
      }

      if (!this.withAssociations) {
        this.uiStore.addNotification(Collection.REGIONS);
      }
    }
  }
});
