


































































import LoadingIndicator from '@/components/shared/LoadingIndicator.vue';
import Search from '@/components/shared/Search.vue';
import EmptyDataset from '@/components/shared/EmptyDataset.vue';
import BaseResourceConfigMixin from '@/components/shared/ResourceConfigMixin';
import moment from 'moment';
import Vue, { VueConstructor } from 'vue';
import { mapStores } from 'pinia';
import {
  LocationPanel,
  LOCATIONS_PANELS_LAYOUT,
  useUiStore
} from '@/store/ui-store';
import { useLocationsStore } from '@/store/locations-store';
import { useRegionsStore } from '@/store/regions-store';
import { Location } from '@/model/location';
import { Region } from '@/model/region';
import ConfigListElement from '@/components/shared/ConfigListElement.vue';
import I18n from '@/config/i18n';

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof BaseResourceConfigMixin>
>).extend({
  components: {
    LoadingIndicator,
    Search,
    EmptyDataset,
    ConfigListElement
  },
  mixins: [BaseResourceConfigMixin],
  data() {
    return {
      searchTerms: ''
    };
  },
  computed: {
    ...mapStores(useUiStore, useLocationsStore, useRegionsStore),
    isFetchingLocations(): boolean {
      return this.locationsStore.fetching;
    },
    isFetchingRegions(): boolean {
      return this.regionsStore.fetching;
    },
    locations(): Location[] {
      return this.locationsStore.entities;
    },
    currentLocation(): Location {
      return this.locationsStore.currentEntity;
    },
    regions(): Region[] {
      return this.regionsStore.entities;
    },
    getRegionsToLoop(): Region[] {
      const regions = [...this.regions];
      regions.splice(regions.length, 0, {
        id: null,
        name: String(I18n.t('locations.noRegion')),
        vendor_id: this.vendor.id
      });

      return regions;
    },
    getLocationsWithoutRegion(): Location[] {
      return this.locationsStore.getLocationsWithoutRegion;
    }
  },
  methods: {
    isClosed(location) {
      return (
        location.closed_from !== null &&
        moment().isSameOrAfter(moment(location.closed_from), 'day')
      );
    },
    isClosingPlanned(location) {
      return (
        location.closed_from !== null &&
        moment().isBefore(moment(location.closed_from), 'day')
      );
    },
    getLocationsFiltered(locations) {
      if (this.searchTerms) {
        return locations.filter(location => {
          const regex = new RegExp(this.searchTerms, 'gi');
          return regex.test(`${location.name}`);
        });
      }
      return locations;
    },
    getLocationsForRegionId(regionId: number): Location[] {
      return this.locationsStore.getLocationsForRegionId(regionId);
    },
    editLocation(location) {
      this.uiStore.openPanel(
        LOCATIONS_PANELS_LAYOUT,
        LocationPanel.EDIT_LOCATION
      );
      this.locationsStore.setCurrentEntity(location);
    }
  }
});
