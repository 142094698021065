



















































import Vue from 'vue';
import { mapStores } from 'pinia';
import { useLocationsStore } from '@/store/locations-store';
import { Location } from '@/model/location';
import { useUsersStore } from '@/store/users-store';
import LocationsList from '@/components/locations/LocationsList.vue';
import AddLocation from '@/components/locations/AddLocation.vue';
import EditLocation from '@/components/locations/EditLocation.vue';
import AddRegion from '@/components/regions/AddRegion.vue';
import AddWorkplace from '@/components/workplaces/AddWorkplace.vue';
import {
  LocationPanel,
  LOCATIONS_PANELS_LAYOUT,
  useUiStore
} from '@/store/ui-store';
import PlainButton from '@/components/shared/PlainButton.vue';

export default Vue.extend({
  components: {
    LocationsList,
    AddLocation,
    EditLocation,
    AddRegion,
    AddWorkplace,
    PlainButton
  },
  data() {
    return {
      LOCATION_PANEL: LocationPanel
    };
  },
  computed: {
    ...mapStores(useUiStore, useLocationsStore, useUsersStore),
    isFetching(): boolean {
      return this.locationsStore.fetching;
    },
    errorOccurred(): boolean {
      return this.locationsStore.failure;
    },
    locations(): Location[] {
      return this.locationsStore.entities;
    },
    currentLocation(): Location {
      return this.locationsStore.currentEntity;
    },
    vendorId(): number | null {
      return this.usersStore.loggedInVendorId;
    },
    isFromLPittet(): boolean {
      return this.usersStore.isFromLPittet;
    },
    openedPanels(): string[] {
      return this.uiStore.openedPanels;
    },
    currentPanelIndex(): number {
      return this.uiStore.currentPanelIndex;
    },
    isAdmin(): boolean {
      return this.usersStore.isAdmin;
    }
  },
  created() {
    this.locationsStore.findAll(this.usersStore.loggedInVendorId, true, false);
    this.uiStore.openPanel(
      LOCATIONS_PANELS_LAYOUT,
      LocationPanel.LOCATIONS_LIST
    );
    if (this.currentLocation?.id) {
      this.uiStore.openPanel(
        LOCATIONS_PANELS_LAYOUT,
        LocationPanel.EDIT_LOCATION
      );
    }
  },
  methods: {
    isPanelOpened(panel) {
      return this.uiStore.isPanelOpened(panel);
    },
    addLocation() {
      this.locationsStore.unsetCurrentEntity();
      this.uiStore.openPanel(
        LOCATIONS_PANELS_LAYOUT,
        LocationPanel.ADD_LOCATION
      );
    }
  }
});
