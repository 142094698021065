














































































































































import ConfirmModal, {
  IConfirmModal
} from '@/components/shared/ConfirmModal.vue';
import BackButtonTitle from '@/components/shared/BackButtonTitle.vue';
import LocationForm from '@/components/locations/LocationForm.vue';
import BaseLocationMixin from '@/components/locations/BaseLocationMixin';
import CheckEventsConflictsModal from '@/components/timetables/CheckEventsConflictsModal.vue';
import DateInput from '@/components/shared/forms/DateInput.vue';
import resourceService from '@/services/resource.service';
import moment from 'moment';
import Vue, { VueConstructor } from 'vue';
import { mapStores } from 'pinia';
import { AlertType, LocationPanel, useUiStore } from '@/store/ui-store';
import { useLocationsStore } from '@/store/locations-store';
import { useUsersStore } from '@/store/users-store';
import { Collection } from '@/store/common/collections';
import { ConflictEventAction } from '@/constants';
import ResourceConfigMixin from '@/components/shared/ResourceConfigMixin';
import { Location } from '@/model/location';
import PlainButton from '@/components/shared/PlainButton.vue';

export default (Vue as VueConstructor<
  Vue &
    InstanceType<typeof BaseLocationMixin> &
    InstanceType<typeof ResourceConfigMixin>
>).extend({
  components: {
    ConfirmModal,
    BackButtonTitle,
    LocationForm,
    CheckEventsConflictsModal,
    DateInput,
    PlainButton
  },
  mixins: [BaseLocationMixin, ResourceConfigMixin],
  data() {
    return {
      dateFrom: '',
      isClosed: false,
      today: moment().toDate(),
      actions: [ConflictEventAction.CANCEL, ConflictEventAction.CHECK_DETAILS],
      deleteLocationModal: {} as IConfirmModal,
      deleteClosedFromModal: {} as IConfirmModal
    };
  },
  computed: {
    ...mapStores(useUiStore, useLocationsStore, useUsersStore),
    closedFromIsPast(): boolean {
      return moment().isSameOrAfter(
        moment(this.currentLocation.closed_from),
        'day'
      );
    },
    showMessageClosedButNotDeletable(): boolean {
      return (
        this.currentLocation.closed_from !== null &&
        moment().isBefore(moment(this.currentLocation.closed_from), 'day')
      );
    },
    humanizedClosedFrom(): string {
      return moment(this.currentLocation.closed_from).format('DD.MM.YYYY');
    },
    location(): Location | null {
      return this.locationsStore.currentEntity;
    }
  },
  mounted() {
    this.deleteLocationModal = (this.$refs
      .deleteLocationModal as unknown) as IConfirmModal;
    this.deleteClosedFromModal = (this.$refs
      .deleteClosedFromModal as unknown) as IConfirmModal;
  },
  methods: {
    closeCurrentPanel() {
      this.uiStore.closePanel(LocationPanel.EDIT_LOCATION);
      this.locationsStore.unsetCurrentEntity();
      this.locationsStore.unsetValidationErrors();
    },
    openClosedFromLocationModal() {
      this.$bvModal.show('closed-from-modal');
    },
    async verifyClosedFromRequest() {
      await resourceService.verifyClosedFrom(
        this.currentLocation.id,
        this.dateFrom
      );
    },
    setValidationErrorsClosedFrom(errors) {
      this.locationsStore.$patch({
        validationErrors: errors
      });
    },
    async onSaveClosedFromModal(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$bvModal.show('check-events-conflicts-modal-closed-from');
    },
    async saveClosedFromRequest() {
      try {
        this.locationsStore.saving = true;
        await resourceService.closedFrom(this.location.id, this.dateFrom);
        this.locationsStore.$patch({
          currentEntity: {
            ...this.location,
            closed_from: this.dateFrom
          }
        });
        this.locationsStore.saving = false;
      } catch (exception) {
        this.uiStore.alert(exception.message);
      }
      this.$bvModal.hide('closed-from-modal');
    },
    async openDeleteClosedFromModal() {
      this.deleteClosedFromModal.openModal(
        this.$t('confirmModal.locationReopen.message', {
          location: this.location.name
        }),
        {
          okTitle: this.$t('button.open'),
          okVariant: 'primary'
        }
      );
    },
    async onConfirmClosedFromDeletion() {
      try {
        this.deleteClosedFromModal.sendingData = true;
        await resourceService.reopenLocation(this.location.id);
        this.locationsStore.$patch({
          currentEntity: {
            ...this.location,
            closed_from: null
          }
        });
        this.deleteClosedFromModal.closeModal();
        this.isClosed = false;
      } catch (exception) {
        this.uiStore.alert(exception.message);
      }
      this.deleteClosedFromModal.sendingData = false;
    },
    openDeleteLocationModal() {
      this.deleteLocationModal.openModal(
        this.$t('confirmModal.deleteLocation.message', {
          locationName: this.currentLocation.name
        }),
        {
          okTitle: this.$t('button.delete'),
          okVariant: 'danger'
        }
      );
    },
    async onConfirmLocationDeletion() {
      const locationSuccessfullyDeleted = await this.locationsStore.delete(
        this.currentLocation
      );
      this.deleteLocationModal.sendingData = false;
      if (locationSuccessfullyDeleted) {
        this.deleteLocationModal.closeModal();
        this.closeCurrentPanel();
        this.uiStore.alert(this.$t('toast.locationDeleted'), AlertType.SUCCESS);
      }
    },
    async editLocation({ location }) {
      await this.locationsStore.edit(location, [Collection.REGIONS]);
    }
  }
});
