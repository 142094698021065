



































































































































import TextInput from '@/components/shared/forms/TextInput.vue';
import Vue from 'vue';
import SubmitButton from '@/components/shared/SubmitButton.vue';
import SelectInput from '@/components/shared/forms/SelectInput.vue';
import { mapStores } from 'pinia';
import { useLocationsStore } from '@/store/locations-store';
import { useUsersStore } from '@/store/users-store';
import ColorInput from '@/components/shared/forms/ColorInput.vue';
import { Location } from '@/model/location';
import { Workplace } from '@/model/workplace';
import { useWorkplacesStore } from '@/store/workplaces-store';
import { useRegionsStore } from '@/store/regions-store';
import {
  LocationPanel,
  LOCATIONS_PANELS_LAYOUT,
  useUiStore
} from '@/store/ui-store';
import ButtonGroup from '@/components/shared/ButtonGroup.vue';
import PlainButton from '@/components/shared/PlainButton.vue';

export default Vue.extend({
  components: {
    ColorInput,
    TextInput,
    SubmitButton,
    SelectInput,
    PlainButton,
    ButtonGroup
  },
  props: {
    locationToEdit: {
      type: Object,
      default: () => {}
    },
    withAssociations: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      location: {} as Partial<Location>
    };
  },
  computed: {
    ...mapStores(
      useRegionsStore,
      useLocationsStore,
      useUsersStore,
      useWorkplacesStore,
      useUiStore
    ),
    validationErrors(): {} {
      return this.locationsStore.validationErrors;
    },
    sendingData(): boolean {
      return this.locationsStore.saving;
    },
    workplaces(): Workplace[] {
      return this.workplacesStore.entities;
    },
    regionsOptions(): {} {
      return this.regionsStore.entities.map(region => ({
        value: region.id,
        label: region.name
      }));
    }
  },
  watch: {
    locationToEdit() {
      this.fillExistingData();
    }
  },
  created() {
    this.fillExistingData();
  },
  methods: {
    fillExistingData() {
      this.location = {
        id: this.locationToEdit ? this.locationToEdit.id : '',
        color: this.locationToEdit ? this.locationToEdit.color : '',
        name: this.locationToEdit ? this.locationToEdit.name : '',
        short_name: this.locationToEdit ? this.locationToEdit.short_name : '',
        street: this.locationToEdit ? this.locationToEdit.street : '',
        complement: this.locationToEdit ? this.locationToEdit.complement : '',
        zip: this.locationToEdit ? this.locationToEdit.zip : '',
        city: this.locationToEdit ? this.locationToEdit.city : '',
        region_id: this.locationToEdit ? this.locationToEdit.region_id : ''
      };
    },
    addRegion() {
      this.uiStore.openPanel(LOCATIONS_PANELS_LAYOUT, LocationPanel.ADD_REGION);
    },
    onSubmit() {
      this.$emit('onFormValidated', {
        location: this.location
      });
    }
  }
});
